.section-with-boxes {
    position: relative;
    /* Necessário para posicionar corretamente o pseudo-elemento */
    background-color: #00AFEF;
    /* Cor de fundo laranja */
    background-image: url('../assets/images/secao.png'), radial-gradient(circle, #00afef, #1092d4, #1977b7, #1c5c9a, #1c437d);
    /* Imagem de fundo */
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    width: 80%;
    margin: 30px auto;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    /* Para garantir que o pseudo-elemento não ultrapasse os limites da borda */
}

.section-with-boxes p {
    color: #fff;
    text-align: center;
    /* Centraliza o texto */
    margin: 0;
    /* Remove margens padrão */
}

.section-with-boxes h1 {
    color: #fff;
    font-size: 28px;
}

.section-with-boxes h5 {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
}

.section-with-boxes .section-title {
    font-size: 30px;
    font-weight: bold;


}

.section-with-boxes .section-subtitle {
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 10px;

}

.section-with-boxes .box-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* Permite que os boxes quebrem para a próxima linha em telas menores */
}

.section-with-boxes .box {
    width: 100px;
    height: 100px;
    /* Ajustado para dar mais espaço */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Centraliza verticalmente */
    background: linear-gradient(to top, #2868c9, #00AFEF);
    margin: 5px;
    /* Espaçamento externo */
    overflow: hidden;
    padding: 10px;
    /* Espaçamento interno */
    border-radius: 10px;
    /* Borda arredondada */
}

.section-with-boxes .box:hover {
    background: rgba(13, 58, 117, 0.2);
    box-shadow: 7px 5px 56px -14px #ffdeb3ab;
}

/* .section-with-boxes .box:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px #C3D900;
   } */

.section-with-boxes .center-icon {
    width: 100%;
    /* Largura total do container */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* Espaço entre ícone e texto */
}

.icon-img {
    width: 50px;
    /* Ajuste a largura conforme necessário */
    height: 50px;
    /* Ajuste a altura conforme necessário */
    object-fit: contain;
    /* Mantém a proporção do ícone sem cortá-lo */
}